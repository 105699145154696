@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/datadesign/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/datadesign/src/scss/utility/_mixin.scss";

.top{
  @include add_prefix(font-feature-settings, "palt");
  &.noplay{
    .top__main{
      @include max-screen(767px) {
        background: url('../../img/top/main_img_sp.jpg') center/cover no-repeat;
      }
      &__video{
        video{
          opacity: 0;
        }
      }
    }

  }
  &__main{
    position: relative;
    height: 100vh;
    overflow: hidden;
    &:before,&:after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      // visibility: hidden;
      z-index: 1;
      @include max-screen(767px) {
        display: none;
      }
    }
    &:before{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 100vh 380px 0 0;
      border-color: $color01 transparent transparent transparent;
      opacity: 0.9;
    }
    &:after{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 100vh 0 0 380px;
      border-color: transparent transparent transparent #00BFC9 ;
      opacity: 0.58;
    }
    &__video{
      height: 100vh;
      position: relative;
      // overflow: hidden;
      z-index: 0;
      @include PC() {
        background: url('../../img/top/load.jpeg') center/cover no-repeat;
      }
      &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #000;
        opacity: 0.4;
        z-index: 1;
      }
      video{
        position: absolute;
        left: 50%;
        top: 50%;
        @include transform_c(translate(-50%,-50%));
        right: 0;
        bottom: 0;
        @include PC() {
          width: 100%;
        }
        @include screen(768px, 1024px) {
          width: auto;
          height: 100%;
        }
        @include max-screen(767px) {
          width: 100%;
          // height: 100%;
        }
      }
    }
    &__txt{
      text-align: center;
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      // visibility: hidden;
      margin-top: -67px;
      @include max-screen(767px) {
        left: 20px;
        top: 57%;
        @include transform_c(translate(0,-50%));
        text-align: left;
        width: auto;
      }
      img{
        width: 398px;
        margin-bottom: 16px;
        @include max-screen(767px) {
          width: 263px;
          margin-bottom: 10px;
        }
      }
      .txth1{
        @include font-size(15);
        line-height: 26px;
        letter-spacing: 0.12em;
        color: #fff;
        @include max-screen(767px) {
          font-weight: bold;
          line-height: 28px;
          letter-spacing: 0.22em;
        }
      }
    }
    @keyframes arrow_down {
      0% {
        height: 70px; }
      100% {
        height: 0; }
    }
    @keyframes arrow_down_sp {
      0% {
        height: 70px; }
      100% {
        height: 0; }
    }
    .ar_scroll{
      position: absolute;
      bottom: 0;
      z-index: 3;
      left: 70px;
      display: inline-block;
      padding-bottom: 80px;
      visibility: hidden;
      @include max-screen(767px) {
        bottom: 0;
        height: 70px;
      }
      &:before{
        content: "";
        width: 1px;
        height: 70px;
        position: absolute;
        top: 115px;
        left: 50%;
        background: #fff;
        animation-name: arrow_down;
        animation-duration: 1.7s;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
        animation-direction: reverse;
        @include max-screen(767px) {
          animation-name: arrow_down_sp;
          height: 70px;
        }
      }
    }
    &__news{
      position: absolute;
      right: 0;
      bottom: 0;
      background: #fff;
      padding: 28px;
      width: 50%;
      color: $color01;
      // visibility: hidden;
      @include clearfix();
      @include max-screen(767px) {
        width: auto;
        left: 20px;
        background: $color01;
        color: #fff;
        padding: 10px 20px;
      }
      &__title{
        @include font-size(18);
        letter-spacing: 0.14em;
        font-weight: bold;
        font-family: $roboto;
        position: relative;
        padding-right: 18px;
        float: left;
        width: 95px;
        @include max-screen(767px) {
          @include font-size(16);
          line-height: 28px;
          letter-spacing: 0.08em;
          width: 70px;
          padding: 14px 0;
          padding-right: 10px;
        }
        &:after{
          content: "";
          width: 1px;
          height: 15px;
          background: #BACCE4;
          position: absolute;
          right: 15px;
          top: 50%;
          @include transform_c(translateY(-50%));
          @include max-screen(767px) {
            right: 10px;
            height: auto;
            top: 4px;
            bottom: 4px;
            @include transform_c(none);
          }
        }
      }
      ul{
        float: left;
        width: calc(100% - 95px);
        @include max-screen(767px) {
          width: calc(100% - 70px);
        }
        li{
          display: block;
          margin-right: 2px;
          a{
            display: block;
            color: $color01;
            padding-right: 30px;
            position: relative;
            background: url('../../img/common/ar_blue.svg') right center no-repeat;
            background-size: 18px;
            color: $color01;
            @include clearfix();
            @include max-screen(767px) {
              background: none;
              padding: 0;
              color: #fff;
            }
            span{
              display: block;
              vertical-align: bottom;
            }
            .txtDate{
              @include font-size(15);
              line-height: 26px;
              letter-spacing: 0.02em;
              font-family: $roboto;
              padding-right: 15px;
              float: left;
              width: 100px;
              position: relative;
              top: 2px;
              @include max-screen(767px) {
                @include font-size(11);
                line-height: 19px;
                padding-right: 0;
                float: none;
                width: 100%;
                top: 0;
              }
            }
            .txtTitle{
              font-weight: bold;
              @include font-size(16);
              line-height: 26px;
              letter-spacing: 0.02em;
              display: block;
              width: calc(100% - 100px);
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              @include max-screen(767px) {
                @include font-size(13);
                line-height: 19px;
                letter-spacing: 0.02em;
                width: 100%;
                white-space: normal;
                position: relative;
                @include multiLineEllipsisForFF(19px,2,#00439B);
                &:after{
                  // content: "...";
                  // position: absolute;
                  // right: 0;
                  // bottom: 0;
                  // color: #fff;
                }
              }
            }
          }
        }
      }
    }
    &.animated{
      &:before,&:after,.top__main__txt,.ar_scroll{
        visibility: visible;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
      }
      .top__main__news{
        visibility: visible;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
      }
    }
  }
  &.iphone{
    @include max-screen(767px) {
      .top__main,.top__main__video{
        height: calc(100vh - 45px);
      }
    }
  }
  &__bl01{
    position: relative;
    padding: 111px 0 120px;
    text-align: center;
    @include max-screen(767px) {
      padding: 75px 0 86px;
    }
    &:after{
      background: #F7F7F7;
      content: "";
      width: 50%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      @include max-screen(767px) {
        background: url('../../img/top/bg_01.png') center bottom/100% no-repeat;
        width: 100%;
      }
    }
    &__title{
      margin-bottom: 44px;
      @include max-screen(767px) {
        margin-bottom: 40px;
        padding: 0 20px;
      }
      .txtEn{
        @include font-size(27);
        line-height: 47px;
        letter-spacing: 0em;
        font-family: $roboto;
        font-weight: 100;
        display: block;
        margin-bottom: 5px;
        @include max-screen(767px) {
          @include font-size(24);
          line-height: 42px;
          letter-spacing: 0.02em;
        }
      }
      .txtTitle{
        @include font-size(55);
        line-height: 75px;
        letter-spacing: 0em;
        font-weight: 500;
        margin-bottom: 11px;
        @include max-screen(767px) {
          @include font-size(31);
          line-height: 40px;
          letter-spacing: 0em;
          margin-bottom: 18px;
        }
      }
      .txtSub{
        @include font-size(13);
        line-height: 26px;
        letter-spacing: 0.12em;
        @include max-screen(767px) {
          @include font-size(15);
          line-height: 26px;
          letter-spacing: 0em;
        }
        @include max-screen(320px) {
          @include font-size(12);
        }
      }
    }
    .container{
      @include max-screen(767px) {
        padding: 0;
      }
    }
    &__list{
      margin: 0 -16px 57px;
      @include clearfix();
      @include min-screen(768px) {
        display: block;
        width: auto;
      }
      @include max-screen(767px) {
        margin: 0 0 55px;
        &.owl-carousel .owl-dots .owl-dot{
          width: 10px;
          height: 10px;
          margin: 0 8px;
          &.active{
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    &__item{
      float: left;
      width: 25%;
      padding: 0 16px;
      position: relative;
      visibility: hidden;
      &.animated{
        visibility: visible;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
      }
      &.it2{
        animation-delay: 0.2s;
        -webkit-animation-duration: 1.3s;
        animation-duration: 1.3s;
      }
      &.it3{
        animation-delay: 0.6s;
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
      }
      &.it4{
        animation-delay: 0.9s;
        -webkit-animation-duration: 1.7s;
        animation-duration: 1.7s;
      }
      &.it5{
        animation-delay: 1.2s;
        -webkit-animation-duration: 1.9s;
        animation-duration: 1.9s;
      }
      @include max-screen(767px) {
        width: 100%;;
      }
      &__title{
        @include font-size(14);
        line-height: 20px;
        letter-spacing: 0.04em;
        font-weight: bold;
        @include max-screen(767px) {
          @include font-size(17);
          line-height: 23px;
          letter-spacing: 0.12em;
        }
      }
      &__en{
        @include font-size(30);
        line-height: 52px;
        letter-spacing: 0.02em;
        font-weight: 100;
        font-family: $roboto;
        display: block;
        @include max-screen(767px) {
          @include font-size(36);
        }
      }
    }
    &__btn{
      text-align: center;
    }
  }

  &__bl02{
    position: relative;
    overflow: hidden;
    &:before,&:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      // width: 695px;
      width: 43.5%;
      height: 100%;
      opacity: 0.14;
      z-index: -1;
      @include max-screen(767px) {
        width: 100%;
      }
    }
    &:before{
      background: url('../../img/top/bg_before.svg') bottom right no-repeat;
      background-size: cover;
    }
    &:after{
      background: url('../../img/top/bg_after.svg') top right no-repeat;
      background-size: cover;
    }
    &__inner{
      position: relative;
      padding: 112px 0;
      @include max-screen(767px) {
        padding: 85px 0 88px;
      }
      &:before{
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 290px;
        height: 56%;
        z-index: -1;
        background: url('../../img/top/bg_after2.svg') top/cover no-repeat;
        @include max-screen(767px) {
          display: none;
        }
      }
      .title_cm{
        @include min-screen(768px) {
          margin-bottom: 10px;
          &__jp{
            position: relative;
            top: -3px;
          }
        }
      }
    }
    .txtSub{
      @include font-size(15);
      line-height: 27px;
      letter-spacing: 0.08em;
      font-weight: 500;
      text-align: center;
      margin-bottom: 50px;
      @include max-screen(767px) {
        line-height: 26px;
        text-align: left;
        letter-spacing: 0;
        margin-bottom: 18px;
      }
    }
    &__box1{
      margin: 0 -10px 35px;
      @include clearfix();
      @include max-screen(767px) {
        margin: 0 -5px 18px;
      }
      &__item{
        text-align: center;
        padding: 0 10px 20px;
        width: 25%;
        float: left;
        visibility: hidden;
        &.animated{
          visibility: visible;
          -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
        }
        &.it2{
          animation-delay: 0.3s;
          -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
        }
        &.it3{
          animation-delay: 0.5s;
          -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
        }
        &.it4{
          animation-delay: 0.7s;
          -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
        }
        &.it5{
          animation-delay: 0.9s;
          -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
        }
        &.it6{
          animation-delay: 1.1s;
          -webkit-animation-duration: 1.6s;
          animation-duration: 1.6s;
        }
        &.it7{
          animation-delay: 1.3s;
          -webkit-animation-duration: 1.7s;
          animation-duration: 1.7s;
        }
        &.it8{
          animation-delay: 1.5s;
          -webkit-animation-duration: 1.9s;
          animation-duration: 1.9s;
        }
        &.it9{
          animation-delay: 1.6s;
          -webkit-animation-duration: 2s;
          animation-duration: 2s;
          @include max-screen(767px) {
            .txtTitle{
              @include font-size(11);
            }
          }
        }
        @include max-screen(767px) {
          width: 33.333%;
          padding: 0 5px 10px;
        }
      }
      &__inner{
        border: 2px dotted #E1E9F3;
        background: #fff;
        padding: 46px 15px 30px;
        @include max-screen(767px) {
          border-width: 2px;
          padding: 20px 3px 10px;
          border-radius: 3px;
        }
      }
      &__img{
        margin-bottom: 26px;
        position: relative;
        display: table;
        width: 100%;
        @include max-screen(767px) {
          margin-bottom: 5px;
        }
        .imgInner{
          display: table-cell;
          vertical-align: middle;
        }
        @include max-screen(767px) {
          .ico_car{
            max-width: 58px;
          }
          .ico_fly{
            max-width: 60px;
          }
          .ico_cel{
            max-width: 21px;
          }
          .ico_book{
            max-width: 25px;
          }
          .ico_more{
            max-width: 15px;
          }
          .ico_01{
            max-width: 33px;
          }
          .ico_02{
            max-width: 36px;
          }
          .ico_03{
            max-width: 21px;
          }
          .ico_04{
            max-width: 36px;
          }
          .ico_06{
            max-width: 23px;
          }
          .ico_07{
            max-width: 48px;
          }
        }
      }
      .txtTitle{
        display: block;
        @include font-size(18);
        letter-spacing: 0.14em;
        color: $color01;
        @include max-screen(767px) {
          @include font-size(13);
          line-height: 21px;
          letter-spacing: 0.04em;
        }
      }
    }
    .pddsp{
      @include max-screen(767px) {
        padding: 0;
        margin-left: -25px;
        max-width: none;
      }
    }
    &__box2{
      @include clearfix();
      margin: 0 0 88px;
      padding: 0 35%;
      @include min-screen(768px) {
        display: block;
        width: auto;
      }
      .owl-stage-outer{
        overflow: visible;
      }
      @include max-screen(767px) {
        margin: 0 0 88px;
        padding: 0;
        padding-right: 45%;
      }
      &.owl-carousel .owl-dots{
        bottom: -50px;
        right: -15px;
      }
      &__item{
        float: left;
        width: 100%;
        padding: 0 22px;
        @include max-screen(767px) {
          width: 100%;
          padding: 0;
          border-right: 1px solid #E5E5E5;
          padding: 0 15px;
        }
      }
      &__img{
        display: block;
        margin-bottom: 25px;
        position: relative;
        overflow: hidden;
        @include max-screen(767px) {
          margin-bottom: 10px;
        }
      }
      img{
        width: 100%;
        border-radius: 2px;
        @extend .imgScale;
        @include max-screen(767px) {
          border-radius: 0;
        }
      }
      a{
        display: block;
        line-height: 0;
        position: relative;
        @include min-screen(768px) {
          opacity: 1;
          &:hover{
            .imagetb{
              @include transform_c(scale(1.1));
            }
          }
        }
      }
      .meta{
        display: block;
        margin-top: 15px;
        padding-left: 2px;
      }
      .txtCate{
        display: inline-block;
        vertical-align: middle;
        @include font-size(10);
        line-height: 20px;
        letter-spacing: 0.05em;
        font-weight: bold;
        color: #fff;
        background: $color01;
        padding: 0 18px;
        margin-right: 0;
        margin-bottom: 5px;
        @include max-screen(767px) {
          @include font-size(11);
          line-height: 14px;
          letter-spacing: 0;
          padding: 3px 6px;
          margin-bottom: 5px;
          min-width: 0;
          text-align: center;
          &:not(.txtcateFirst){
            background: none;
            color: $color01;
            padding: 0;
            @include font-size(10);
            line-height: 12px;
            display: none;
            min-width: 0;
          }
        }
      }
     .txtCatesp{
        color: $color01;
        padding: 0;
        @include font-size(10);
        line-height: 12px;
        font-weight: bold;
        display: inline-block;
        margin-top: 3px;
      }
      .txtTitle{
        @include font-size(18);
        line-height: 28px;
        letter-spacing: 0.02em;
        font-weight: bold;
        margin-bottom: 10px;
        display: block;
        @include max-screen(767px) {
          @include font-size(17);
          line-height: 26px;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .txtDes{
        @include font-size(14);
        line-height: 26px;
        letter-spacing: 0.02em;
        font-weight: 500;
        color: #4D4D4D;
      }
    }
  }
  &__bl03{
    padding: 118px 70px 125px;
    background: #F7F7F7;
    position: relative;
    overflow: hidden;
    @include max-screen(767px) {
      padding: 70px 0;
      background: #fff;
    }
    &:before{
      content: "";
      width: 1px;
      height: 360px;
      background: #808080;
      position: absolute;
      left: 255px;
      top: 0;
      @include transform_c(rotate(45deg));
      transform-origin: top;
      @include max-screen(767px) {
        height: 155px;
        left: 109px;
      }
    }
    .container{
      max-width: 100%;
      padding: 0;
    }
    .title_cm{
      margin-bottom: 40px;
      @include max-screen(767px) {
        margin-bottom: 25px;
        &__en{
          @include font-size(37);
        }
      }
    }
    &__tabTitle{
      text-align: center;
      @include max-screen(767px) {
        padding: 0 15px;
        border-bottom: 1px solid $color01;
        @include clearfix();
      }
      li{
        display: inline-block;
        margin: 0 5px;
        min-width: 232px;
        @include max-screen(767px) {
          width: 33.333%;
          margin: 0;
          min-width: 0;
          padding: 0 2px;
        }
        span{
           text-align: center;
          background: #92AFD4;
          color: #fff;
          padding: 15px;
          @include font-size(15);
          line-height: 26px;
          letter-spacing: 0.02em;
          font-weight: bold;
          cursor: pointer;
          display: block;
          @include transition_c(all 0.3s);
          @include max-screen(767px) {
            padding: 10px 8px;
            @include font-size(14);
            line-height: 18px;
            letter-spacing: 0em;
          }
        }
        &.active,&:hover{
          span{
            background: $color01;
          }
        }
      }
    }
    &__contentT{
      display: none;
      &.active{
        display: block;
      }
      .noBox{
        text-align: center;
        padding: 0 0 30px;
      }
    }
    &__tabContent{
      background: #fff;
      margin-bottom: 56px;
      display: none;
      @include max-screen(767px) {
        background: none;
        margin-left: -30px;
        margin-bottom: 95px;
      }
      &.active{
        display: block;
      }
    }
    &__inner{
      width: calc(70% + 140px);
      padding: 43px 0 10px;
      margin: 0 auto;
      @include max-screen(767px) {
        padding: 20px 0 0;
        width: 100%;
      }
    }
    &__cate{
      text-align: center;
      @include flexbox();
      @include justify-content();
      @include flex-wrap();
      padding-bottom: 35px;
      margin-bottom: 36px;
      border-bottom: 1px dotted #E5E5E5;
      @include max-screen(767px) {
        padding: 0 20px 30px;
        margin-left: 30px;
        @include justify-content(flex-start);
      }
      li{
        padding: 0 17px;
        @include SP() {
          width: 50%;
          padding: 0;
          text-align: left;
        }
        a{
          @include font-size(15);
          line-height: 26px;
          letter-spacing: 0.02em;
          display: block;
          position: relative;
          padding-left: 18px;
          @include SP() {
            padding-right: 10px;
          }
          @include IP5 {
            @include font-size(13);
          }
          &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            width: 12px;
            height: 12px;
            border: 1px solid #000;
          }
        }
        &.active{
          a{
            &:before{
              content: "";
              background: url('../../img/common/ico_check.svg') left bottom no-repeat;
              width: 25px;
              height: 17px;
              top: 2px;
              border: none;
            }
          }
        }
      }
    }
    &__list{
      @include clearfix();
      margin: 0 -23px;
      @include min-screen(768px) {
        display: block;
        width: auto;
      }
      @include max-screen(767px) {
        margin: 0;
        padding-right: 45%;
        &.owl-carousel .owl-dots{
          bottom: -50px;
          right: -15px;
        }
        .owl-stage-outer{
          overflow: visible;
        }
      }
    }
    &__item{
      float: left;
      width: 33.333%;
      padding: 0 23px 53px;
      @include max-screen(767px) {
        width: 100%;
        padding: 0 15px;
        border-right: 1px solid #E5E5E5;
      }
      a{
        display: block;
        &:hover{
          @include min-screen(768px) {
            opacity: 1;
            img,.imagetb{
              @include transform_c(scale(1.1));
            }
          }
        }
      }
      .img_ef{
        margin-bottom: 10px;
        @include max-screen(767px) {
          margin-bottom: 10px;
        }
      }
      img{
        width: 100%;
        @extend .imgScale;
      }
      .txtTitle{
        display: block;
        @include font-size(17);
        line-height: 30px;
        letter-spacing: 0.02em;
        font-weight: 500;
        font-family: $roboto;
        color: $color01;
        margin-bottom: 2px;
        @include max-screen(767px) {
          @include font-size(20);
          line-height: 26px;
          letter-spacing: 0em;
          margin-bottom: 8px;
        }
      }
      .txtCate{
        @include font-size(13);
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #4D4D4D;
        @include max-screen(767px) {
          letter-spacing: 0.05em;
        }
        span{
          display: block;
        }
      }
    }
  }

  &__bl04{
    padding: 118px 0 0;
    position: relative;
    overflow: hidden;
    margin-bottom: 65px;
    @include max-screen(767px) {
      padding: 70px 0 0;
      background: #F7F7F7;
      margin-bottom: 0;
    }
    &:before{
      content: "";
      width: 1px;
      height: 360px;
      background: #808080;
      position: absolute;
      left: 0;
      top: 0;
      @include transform_c(rotate(45deg));
      left: 255px;
      transform-origin: top;
      @include max-screen(767px) {
        height: 155px;
        left: 109px;
      }
    }
    .container{
      max-width: 100%;
      padding: 0;
    }
    .title_cm{
      margin-bottom: 60px;
      @include max-screen(767px) {
        margin: 0 0 35px;
        &__en{
          @include font-size(37);
        }
      }
    }
    &__inner{
      @include min-screen(768px) {
      }
    }
    &__box{
      @include min-screen(1025px) {
        margin-left: -90px;
      }
      @include screen(768px,1024px) {
        margin-left: 0;
      }
      @include max-screen(767px) {
        margin-left: 0;
      }
    }
    &__list{
      padding-right: 60%;
      position: relative;
      @include min-screen(1025px) {
        &:before{
          content: "";
          width: 70px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 90px;
          bottom: 0;
          background: #fff;
          z-index: 10;
        }
      }
      @include min-screen(1810px) {
        padding-right: 65%;
      }
      @include screen(1100px,1200px) {
        padding-right: 50%;
      }
      @include screen(1200px,1400px) {
        padding-right: 55%;
      }
      @include max-screen(767px) {
        padding: 0 50px;
      }
      &.owl-carousel{
        margin-bottom: 60px;
        .owl-dots{
          bottom: -57px;
          @include min-screen(1025px) {
            padding-left: 70px;
          }
        }
        @include min-screen(1025px) {
          .owl-nav .owl-prev{
            left: 90px;
          }
        }
        @include max-screen(767px) {
          padding-bottom: 0;
          margin-bottom: 108px;
          .owl-nav .owl-prev{
            left: 0;
          }
          .owl-dots{
            bottom: -58px;
            right: 0;
          }
        }
        .owl-stage{
        }
        .owl-stage-outer{
          overflow: visible;
        }
      }
      .owl-stage{
        @include flexbox;
        .owl-item{
          border: 1px solid #CCC;
          background: #fff;
        }
      }
    }
    &__item{
      padding: 0 0 20px;
      // float: left;
      width: 100%;
      @include max-screen(767px) {
        background: #fff;
      }
      a{
        display: block;
        &:hover{
          @include min-screen(768px) {
            opacity: 1;
            img,.imagetb{
              @include transform_c(scale(1.1));
            }
          }
        }
      }
      .img_ef{
        margin-bottom: 25px;
      }
      img{
        width: 100%;
        @extend .imgScale;
      }
      .txtCate{
        position: absolute;
        bottom: 0;
        right: 0;
        @include max-screen(767px) {
          @include font-size(10);
        }
        span{
          @include font-size(11);
          line-height: 24px;
          letter-spacing: 0.04em;
          font-weight: bold;
          color: #fff;
          display: inline-block;
          padding: 0 15px;
          background: rgba(0, 0, 0, .7);
        }
      }
      .txtTitle{
        @include font-size(16);
        line-height: 25px;
        letter-spacing: 0.05em;
        font-weight: bold;
        padding: 0 20px;
        margin-bottom: 20px;
        display: block;
        @include max-screen(767px) {
          @include font-size(17);
          line-height: 26px;
          letter-spacing: 0.02em;
          margin-bottom: 10px;
        }
      }
      .txtDate{
        text-align: right;
        display: block;
        padding: 0 20px;
        @include font-size(13);
        letter-spacing: 0.02em;
        img{
          display: inline-block;
          max-width: 15px;
          margin-right: 2px;
        }
        span{
          display: inline-block;
          vertical-align: middle;
          color: #4D4D4D;
        }
      }
    }
  }

  &__bl05{
    margin-bottom: 130px;
    @include max-screen(767px) {
      background: #F7F7F7;
      margin-bottom: 0;
      padding-bottom: 90px;
    }
    .container{
      @include max-screen(767px) {
        padding: 0;
      }
    }
    .boxSelect{
      @include SP() {
        position: relative;
      }
    }
    &__title{
      @include max-screen(767px) {
        @include font-size(17);
        line-height: 60px;
        letter-spacing: 0.08em;
        font-weight: bold;
        background: #808080 url('../../img/common/ar_down_w.svg') right 12px center no-repeat;
        color: #fff;
        text-align: center;
        position: relative;
        margin: 0 20px 20px;
      }
      @include max-screen(320px) {
        @include font-size(14);
      }
    }
    &__tabTitle{
      text-align: center;
      position: relative;
      border-bottom: 1px solid $color01;
      @include flexbox();
      @include justify-content();
      @include flex-wrap();
      margin-bottom: 38px;
      @include max-screen(767px) {
        margin-bottom: 0;
        display: none;
        position: absolute;
        background: #fff;
        left: 20px;
        right: 20px;
        top: 50px;
      }
      li{
        display: block;
        padding: 0 5px;
        @include max-screen(767px) {
          width: 100%;
          padding: 0;
        }
        span{
          display: block;
          border: 1px solid $color01;
          border-bottom: none;
          @include font-size(13);
          line-height: 22px;
          letter-spacing: 0.02em;
          text-align: center;
          min-width: 125px;
          color: $color01;
          font-weight: 500;
          padding: 11px 10px;
          cursor: pointer;
          @include transition_c(all 0.3s);
          @include screen(768px,850px) {
              min-width: 0;
          }

        }
        &.active{
          span{
            background: $color01;
            color: #fff;
            font-weight: bold;
          }
        }
        &:hover{
          @include min-screen(1025px) {
            span{
              background: $color01;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
    &__tabContent{
      display: none;
      text-align: center;
      &.active{
        display: block;
      }
      ul{
        margin-bottom: 62px;
        @include max-screen(767px) {
          border-top: 1px solid #E7E9ED;
          margin-bottom: 36px;
        }
        li{
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid #E7E9ED;
          @include max-screen(767px) {
            padding: 20px 20px 14px;
            margin: 0;
          }
          a{
            display: block;
            @include flexbox();
            @include justify-content(left);
            @include flex-wrap();
            @include align-items(center);
            background: url('../../img/top/ar_gr.svg') right center no-repeat;
            span{
              display: inline-block;
              vertical-align: middle;
            }
            .txtCate{
              width: 83px;
              margin-right: 20px;
              display: inline-block;
              text-align: center;
              @include max-screen(767px) {
                width: 76px;
                margin-right: 10px;
              }
              span{
                display: block;
                @include font-size(11);
                line-height: 20px;
                letter-spacing: 0.020em;
                font-weight: 500;
                background: $color01;
                color: #fff;
                padding: 0 5px;
                @include max-screen(767px) {
                  @include font-size(10);
                }
              }
            }
            .txtDate{
              color: #4D4D4D;
              @include font-size(15);
              line-height: 26px;
              letter-spacing: 0.02em;
              display: inline-block;
              margin-right: 20px;
              @include max-screen(767px) {
                @include font-size(12);
                line-height: 21px;
                margin-right: 0;
              }
            }
            .txtTitle{
              @include font-size(15);
              line-height: 26px;
              letter-spacing: 0.02em;
              font-weight: bold;
              @include PC() {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: calc(100% - 250px);
              }
              @include max-screen(767px) {
                display: block;
                width: 100%;
                padding-right: 15px;
                margin-top: 5px;
                text-align: left;
              }
            }
            &:hover{
              @include min-screen(768px) {
                opacity: 1;
                .txtTitle{
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
    .top__bl01__btn{
      @include flexbox();
      @include justify-content();
      @include flex-wrap();
      @include align-items();
      margin-top: 38px;
      @include max-screen(767px) {
        padding: 0 20px;
        margin-top: 20px;
      }
      a{
        max-width: 246px;
        margin: 0 16px;
        @include max-screen(767px) {
          max-width: 296px;
          margin: 0 0 10px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
// fix ie
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .top__main__news ul li a{
    background-size: 20px;
  }
  .top__bl02__box2 .txtCate{
    padding-top: 2px;
  }
  .top__bl04__item .txtCate{
    padding-top: 3px;
  }
  .top__bl05__tabContent ul li a .txtCate span{
    padding-top: 4px;
  }
}


























